import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

import { Card, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import LazyAnimationImage from '../components/lazeeAnimationImage'

import Head from "../components/head"

const SoldOut = ({data}) => {
  
    return(
      <LazyAnimationImage>
        <div>
            <Layout>
            <Head title="Sold Out" />
            <Card style={{ width: '70%', margin: `20vh auto`, color: `rgb(203, 0, 24)` }}>
              <Card.Body>
                {/* <Card.Title
                  style={{fontSize: `3rem`, margin: `1rem`}}
                  >Your is not accepted.
                </Card.Title> */}
                  <Card.Subtitle 
                    style={{fontSize: `2rem`, margin: `1rem`}}
                    className="mb-2">We're sorry...we're sold out for Christmas 2024!
                  </Card.Subtitle>
                <Card.Text
                style={{margin: `1rem`}}
                >
                  We apologize for the inconvenience.
                </Card.Text>
                <Link to="/">
                  <Button className="soldOut-button" variant="outline-danger">
                    Vicenza's Crostoli Home
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Layout>
        </div>
      </LazyAnimationImage>
    )
}

export default SoldOut

// export const pageQuery = graphql`
//   query { 
//     easterWall: file(relativePath: { eq: "Easter-2021-Background-WhiteWall.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1200, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `